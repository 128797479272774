export default {
    // // 开发环境
    // defaultBaseUrl: '//192.168.3.102:9999',
    // shdHost: 'http://192.168.3.101:888'



    // // // 生产环境
    defaultBaseUrl:'//api.yuduhulian.com',
    shdHost:'/erp'
}