import requestCustom from '@/utils/requestCustom'

// 获取系统通知
export function getSysNoticeList(params) {
  return requestCustom({
    url: "/sysMsg/list",
    method: "get",
    params
  });
}
// 消息已读
export function changeReadState(id) {
  return requestCustom({
    url: `/sysMsg/changeRead/${id}`,
    method: "get"
  });
}