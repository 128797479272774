import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import store from '@/store'
import Login from '../views/Login'
//console.log(store.state.loginState)
const routes = [
    {
        path: '/',
        name: 'home',
        redirect: '/chat/conversation',
        component: () => import('../views/Chat'),
    },
    /* 登陆页 */
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login'),
        meta: {
            title: '登陆环信'
        }
    },
    /* 聊天页 */
    {
        path: '/chat',
        name: 'Chat',
        redirect: '/chat/conversation',
        component: () => import('../views/Chat'),
        meta: {
            title: '开始聊天'
        },
        children: [
          /* 管理端 */
          {
            path: 'erp',
            name: 'Erp',
            meta: {
                title: '商户管理后台',
                requiresAuth: true,
                keepAlive: true, // 设置需要缓存的组件
            },
            component: () =>
                import('../views/erpView/index')
          },
            /* 会话列表 */
            {
                path: 'conversation',
                name: 'Conversation',
                meta: {
                    title: '会话',
                    requiresAuth: false,
                    // keepAlive: true, // 设置需要缓存的组件
                },
                component: () =>
                    import('../views/Chat/components/Conversation'),
                children: [
                    //系统通知详情框
                    {
                        path: 'informdetails',
                        component: () =>
                            import('../views/Chat/components/InformDetails')
                    },
                    //聊天对话框
                    {
                        path: 'message',
                        name: 'Message',
                        component: () =>
                            import('../views/Chat/components/Message')
                    }
                ]
            },
            /* 管理端 */
          {
            path: 'systenNotice',
            name: 'SystenNotice',
            meta: {
                title: '系统通知',
                requiresAuth: true,
                keepAlive: false, // 设置需要缓存的组件
            },
            component: () =>
                import('../views/systemNotice/index')
          },
            /* 联系人页 */
            {
                path: 'contacts',
                name: 'Contacts',
                meta: {
                    title: '联系页',
                    requiresAuth: true,
                    keepAlive: true, // 设置需要缓存的组件
                },
                component: () => import('../views/Chat/components/Contacts'),
                children: [
                    {
                        path: 'message',

                        component: () =>
                            import('../views/Chat/components/Message')
                    },
                    //系统通知详情框
                    {
                        path: 'informdetails',
                        component: () =>
                            import('../views/Chat/components/InformDetails')
                    },
                    {
                        path: 'contactInfo',
                        component: () =>
                            import(
                                '../views/Chat/components/Contacts/components/contactInfo.vue'
                            )
                    }
                ]
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    //console.log('%c Line:92 🥕 to', 'color:#33a5ff', to)

    NProgress.start()

    // const loginState = store.state.loginState
    let EASEIM_loginUser = window.localStorage.getItem('EASEIM_loginUser')

    if (EASEIM_loginUser) {
        EASEIM_loginUser = JSON.parse(EASEIM_loginUser)
    }
    const loginUserFromStorage = EASEIM_loginUser || {}
    if (to.path == '/chat/conversation') {
        next()
        NProgress.done()
    } else if (to.matched.some((record) => record.meta.requiresAuth)) {
        //需要登陆
        if (loginUserFromStorage.user && loginUserFromStorage.accessToken) {
            //token存在，放行
            next()
            NProgress.done()
        } else {
            //token不存在，跳转登陆页面
            next({ path: '/login' })
            NProgress.done()
        }
    } else {
        next()
        NProgress.done()
    }
    if(from.path=='/chat/contacts'){
      store.dispatch('deleteGroupList')
    }
})
export default router
