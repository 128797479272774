import { EaseChatSDK, EaseChatClient } from '@/IM/initwebsdk'
import { setMessageKey, createMessage } from '@/utils/handleSomeData'
import { sendMsg, getStateByMessageId, revocationMsg } from '@/api/custom'
import { getSysNoticeList } from "@/api/systemNotice"
import _ from 'lodash'
// import { ref, toRaw } from 'vue';
import { messageType } from '@/constant'
import { usePlayRing } from '@/hooks'
const { ALL_MESSAGE_TYPE } = messageType

const Message = {
    state: {
        messageList: {},
        unReadMsgIds: [],
        businessMsgState: {},//全局保存业务消息的状态,在消息列表柑橘此状态显示不同视图 key:消息的id  value：业务消息的状态对象
        currentChatId: '',//当前会话的对方环信id
        currentChatType: '',//当前会话的类型
        systemNoticeUnreadNum: 0,//系统通知未读条数
        systemNoticeListData: {},//系统通知列表数据
        messageExt: '',//发送消息时需要携带的扩展参数
    },
    mutations: {
        // 更改 发送消息时需要携带的扩展参数
        UPDATE_messageExt(state, str) {
            state.messageExt = str
        },
        // 更改系统通知未读条数
        UPDATE_systemNoticeUnreadNum(state, num) {
            state.systemNoticeUnreadNum = num
        },
        // 更改系统通知列表数据
        UPDATE_systemNoticeListData(state, list) {
            state.systemNoticeListData = list
        },
        // 更改当前会话id
        UPDATE_CURRENT_CHAT_ID(state, chatId) {
            state.currentChatId = chatId
        },
        // 更改当前会话的类型
        UPDATE_CURRENT_CHAT_TYPE(state, chatType) {
            state.currentChatType = chatType
        },
        // 清空当前会话消息
        DELETE_ALL_MESSAGE_BY_ID(state, conversationKey) {
            if (conversationKey in state.messageList) delete state.messageList[conversationKey]
        },

        // 更新业务消息状态
        UPDATE_BBUSINESS_MSG_STATE(state, stateMap) {
            state.businessMsgState[stateMap.messageId] = { ...stateMap }
        },
        UNDATE_UNREAD_IDS: (state, ids) => {
            state.unReadMsgIds = ids
            localStorage.setItem('unreadMsgIds', JSON.stringify(ids))
        },
        DELETE_UNREAD_ID_ONE: (state, id) => {
            // 删除一个未读id
            let index = state.unReadMsgIds.indexOf(id)
            if (index > -1) {
                state.unReadMsgIds = state.unReadMsgIds.splice(index, 1)
                localStorage.setItem('unreadMsgIds', JSON.stringify(state.unReadMsgIds))
            }
        },
        UPDATE_MESSAGE_LIST: (state, msgBody) => {
            const toUpdateMsgList = _.assign({}, state.messageList)
            const listKey = setMessageKey(msgBody)
            if (!toUpdateMsgList[listKey]) {
                toUpdateMsgList[listKey] = []
                _.unionBy(toUpdateMsgList[listKey].push(msgBody), (m) => m.id)
            } else {
                _.unionBy(toUpdateMsgList[listKey].push(msgBody), (m) => m.id)
            }
            state.messageList = toUpdateMsgList
        },
        UPDATE_HISTORY_MESSAGE: (state, payload) => {
            const { listKey, historyMessage } = payload
            const toUpdateMsgList = _.assign({}, state.messageList)
            if (!toUpdateMsgList[listKey]) {
                toUpdateMsgList[listKey] = []
                _.unionBy(
                    toUpdateMsgList[listKey].push(...historyMessage),
                    (m) => m.id
                )
            } else {
                if (toUpdateMsgList[listKey][0].id !== historyMessage[0].id) {
                    _.unionBy(
                        toUpdateMsgList[listKey].unshift(...historyMessage),
                        (m) => m.id
                    )
                }
            }
            state.messageList = toUpdateMsgList
        },
        //清除某条会话消息
        CLEAR_SOMEONE_MESSAGE: (state, payload) => {
            state.messageList[payload] = []
        },
        //撤回删除消息 已读消息
        CHANGE_MESSAGE_BODAY: (state, payload) => {
            const { type, key, mid } = payload
            if (type === 'recall') {
                if (state.messageList[key]) {
                    const res = _.find(
                        state.messageList[key],
                        (o) => o.id === mid
                    )
                    res.isRecall = true
                }
            }
            if (type === 'deleteMsg') {
                if (state.messageList[key]) {
                    const sourceData = state.messageList[key]
                    const index = _.findIndex(
                        state.messageList[key],
                        (o) => o.id === mid
                    )
                    sourceData.splice(index, 1)
                    state.messageList[key] = _.assign([], sourceData)
                }
            }
            if (type === 'read') {
                if (state.messageList[key]) {
                    // 未读消息ids存在，要删掉
                    // 删除一个未读id
                    let hh = state.unReadMsgIds.indexOf(mid)
                    if (hh > -1) {
                        state.unReadMsgIds.splice(hh, 1)
                        localStorage.setItem('unreadMsgIds', JSON.stringify(state.unReadMsgIds))
                    }
                }
            }
            if (type === 'converReaded') {
                if (state.messageList[key]) {
                    let sourceData = state.messageList[key]
                    if (sourceData && sourceData.length) {
                        for (let i = 0; i < sourceData.length; i++) {
                            // // 未读消息ids删掉当前消息id
                            let j = state.unReadMsgIds.indexOf(sourceData[i].id)
                            if (j > -1) {
                                state.unReadMsgIds.splice(j, 1)
                            }
                        }
                        localStorage.setItem('unreadMsgIds', JSON.stringify(state.unReadMsgIds))
                    }
                    state.messageList[key] = _.assign([], sourceData)
                }
            }
        }
    },
    actions: {
        // 获取系统消息未读条数
        getSysNotice: async ({ commit }, params = {
            pageSize: 10,
            pageNum: 1,
            total: 0,
            // sendType: 'SB1000'
        }) => {
            // // 判断角色
            // const temp = localStorage.getItem('customUserInfo')
            //   if (temp) {
            //     const userInfo = JSON.parse(temp)
            //     if(userInfo&&userInfo.roles){
            //       const roleArr = Array.from(userInfo.roles,({roleKey})=>roleKey)
            //       if(roleArr.includes('youxizhishou')){
            //         // 直收商

            //       }
            //       if(roleArr.includes('salesCustomer')){
            //         // 客服
            //         params.sendType = ''
            //         params.sendTypeName = 'saleOrder'
            //       }

            //     }
            //   }

            // 客服
            // params.sendType = ''
            // params.sendTypeName = 'saleOrder'

            try {
                let res = await getSysNoticeList(params)
                commit('UPDATE_systemNoticeUnreadNum', res.params?.wdCount || 0)
                commit('UPDATE_systemNoticeListData', res)
                return res
            } catch (error) {
                console.log("%c Line:181 🍬 error", "color:#93c0a4", error);

            }
        },
        // 获取业务消息状态
        getStateByMessageIdFn: async ({ commit }, msgIds) => {
            let res = await getStateByMessageId({
                messageIds: msgIds.join(',')
            })
            if (res.data) {
                res.data.forEach(r => {
                    commit('UPDATE_BBUSINESS_MSG_STATE', r, r.messageId)
                })
            }

            return res.data
        },
        // 获取本地未读消息IDs
        getLocalUnreadMsgIds: ({ dispatch, commit }, params) => {
            let tempUnreadMsgIds = localStorage.getItem('unreadMsgIds')
            let arr = []
            if (
                tempUnreadMsgIds &&
                JSON.parse(tempUnreadMsgIds).length
            ) {
                arr = JSON.parse(tempUnreadMsgIds)
            } else {
                localStorage.setItem('unreadMsgIds', JSON.stringify(arr))
            }
            commit('UNDATE_UNREAD_IDS', arr)
        },
        // 添加消息到未读IDs
        addMsgToUnreadIds: ({ dispatch, commit, state }, params) => {
            let arr = JSON.parse(JSON.stringify(state.unReadMsgIds))
            if (arr.indexOf(params) == -1) arr.push(params)
            commit('UNDATE_UNREAD_IDS', arr)
        },
        //添加新消息
        createNewMessage: ({ dispatch, commit }, params) => {
            const { isOpenPlayRing, playRing } = usePlayRing()
            const key = setMessageKey(params)
            commit('UPDATE_MESSAGE_LIST', params)
            // if(params.type=='custom'&&(params.customEvent=='b_backlist'||params.customEvent=='b_backorder')){
            //   dispatch('getStateByMessageIdFn',[params.id])
            // }
            //目前根据全局配置进行新消息声音提示，后续计划根据会话级别可进行设置是否声音提示，比如设定免打扰。
            if (isOpenPlayRing.value) {
                const loginUserId = EaseChatClient.user
                if (loginUserId != params.from) {
                    playRing()
                    if (
                        params.type == 'txt' &&
                        params.msg &&
                        params.msg.indexOf('@') > -1
                    ) {
                        if (
                            window.Notification &&
                            Notification.permission !== 'denied'
                        ) {
                            Notification.requestPermission(function (status) {
                                var n = new Notification('您有新消息', {
                                    body: '新消息：' + params.msg
                                })
                            })
                        }
                    }
                }
            }
            dispatch('gatherConversation', key)
        },
        // //获取历史消息 业务消息更新时调用
        // getHistoryMessageForBussiness: async ({ dispatch, commit,state }, params) => {
        //   console.log("%c Line:187 🧀业务消息更新时调用获取历史消息 params", "color:#42b983", params);
        //     const { id, chatType } = params
        //     if(id in state.messageList){
        //       return new Promise((resolve, reject) => {
        //           const options = {
        //               targetId: id,
        //               pageSize: 10,
        //               cursor: state.messageList[id][state.messageList[id].length-1].id,
        //               chatType: chatType,
        //               searchDirection: 'down'
        //           }
        //           EaseChatClient.getHistoryMessages(options)
        //             .then((res) => {
        //                 const { cursor, messages } = res
        //                 messages.length > 0 &&
        //                     messages.forEach((item) => {
        //                         item.read = true
        //                         if(state.unReadMsgIds.indexOf(item.id)>-1){
        //                           item.read = false
        //                         }
        //                         if(item.id){
        //                           // 创建消息
        //                           dispatch('createNewMessage', item)
        //                         }

        //                         if(item.type=='custom'&&item.customEvent=='b_backlist'){
        //                           dispatch('getStateByMessageIdFn',item.id)
        //                         }
        //                     })
        //                 resolve({ messages, cursor })
        //                 //提示会话列表更新
        //                 dispatch('gatherConversation', id)
        //             })
        //             .catch((error) => {
        //                 reject(error)
        //             })
        //       })
        //     }else{
        //       dispatch('getHistoryMessage',{...params,cursor:-1})
        //     }

        // },
        // 发送已读回执
        sendReaded: ({ commit }, msg) => {
            let option = {
                type: "read", // 消息是否已读。
                chatType: "singleChat", // 会话类型，这里为单聊。
                to: msg.from, // 消息接收方的用户 ID。
                id: msg.id, // 需要发送已读回执的消息 ID。
            };
            let message = EaseChatSDK.message.create(option);
            EaseChatClient.send(message);
        },
        //获取历史消息 聊天窗口 查看更多消息
        getHistoryMessage: async ({ dispatch, commit, state }, params) => {
            // console.log("%c Line:187 🧀获取历史消息 params", "color:#42b983", params);
            const { id, chatType, cursor, flag } = params
            return new Promise((resolve, reject) => {
                const options = {
                    targetId: id,
                    pageSize: 10,
                    cursor: cursor,
                    chatType: chatType,
                    searchDirection: 'up'
                }
                EaseChatClient.getHistoryMessages(options)
                    .then((res) => {
                        const { cursor, messages } = res
                        let businessMsg = []
                        let busIds = []
                        messages.length > 0 &&
                            messages.forEach((item) => {
                                item.read = true
                                if (state.unReadMsgIds.indexOf(item.id) > -1) {
                                    item.read = false
                                }
                                // if(item.type=='custom'&&(item.customEvent=='b_backlist'||item.customEvent=='b_backorder')){
                                //   businessMsg.push(item)
                                //   busIds.push(item.id)
                                // }

                                if (chatType == "singleChat" && EaseChatClient.user != item.from) {
                                    dispatch('sendReaded', item)
                                }
                            })
                        if (busIds.length) dispatch('getStateByMessageIdFn', busIds)
                        resolve({ messages, cursor })
                        if (flag == 'createMyMsg') {
                            businessMsg.length > 0 && dispatch('createNewMessage', businessMsg[0])
                        } else {
                            commit('UPDATE_HISTORY_MESSAGE', {
                                listKey: id,
                                historyMessage: _.reverse(messages)
                            })
                        }
                        //提示会话列表更新
                        dispatch('gatherConversation', id)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        //发送展示类型消息
        sendShowTypeMessage: async ({ dispatch, commit, state }, params) => {
            return new Promise((resolve, reject) => {
                //console.log('%c Line:126 🍖 params', 'color:#ed9ec7', params)
                //主要作用为创建消息Options中附件会有上传失败的回调函数。
                //传入errorCallback，让附件类型消息在上传失败时调用reject抛出error

                // params
                let params2 = {}
                let msgData = {}
                if (params.msgType == 'txt') {
                    if (
                        params.msgOptions.msg &&
                        /^订单编号/.test(params.msgOptions.msg)
                    ) {
                        // 发送的是订单
                        msgData = {
                            fromUser: EaseChatClient.user,
                            content: params.msgOptions.msg,
                            senType: 'order'
                        }
                        params.msgOptions.customExts = params.msgOptions.msg
                        params.msgOptions.customEvent = msgData.senType
                        params = {
                            msgType: 'custom',
                            msgOptions: params.msgOptions
                        }
                    } else {
                        // 普通文本
                        msgData = {
                            fromUser: EaseChatClient.user,
                            content: params.msgOptions.msg,
                            senType: 'text'
                        }
                    }
                } else if (params.msgType == 'img') {
                    msgData = {
                        fromUser: EaseChatClient.user,
                        content: params.msgOptions.imgUrl,
                        senType: 'image'
                    }
                } else if (params.msgType == 'audio') {
                    let content = {
                        url: params.msgOptions.file.url,
                        length: params.msgOptions.length
                    }
                    msgData = {
                        fromUser: EaseChatClient.user,
                        content: JSON.stringify(content),
                        senType: 'audio'
                    }
                } else if (params.msgType == 'video') {
                    msgData = {
                        fromUser: EaseChatClient.user,
                        content: params.msgOptions.url,
                        senType: 'video'
                    }
                    params.msgOptions.customExts = params.msgOptions.url
                    params.msgOptions.customEvent = msgData.senType
                    params = {
                        msgType: 'custom',
                        msgOptions: params.msgOptions
                    }
                } else if (params.msgType == 'goods') {
                    msgData = {
                        fromUser: EaseChatClient.user,
                        content: params.msgOptions.goods,
                        senType: 'goods'
                    }
                    params.msgOptions.customExts = params.msgOptions.goods
                    params.msgOptions.customEvent = msgData.senType
                    params = {
                        msgType: 'custom',
                        msgOptions: params.msgOptions
                    }
                }
                else {
                    msgData = {
                        fromUser: EaseChatClient.user,
                        content: params.msgOptions.content,
                        senType: params.msgType
                    }
                    params.msgOptions.customExts = params.msgOptions.content
                    params.msgOptions.customEvent = msgData.senType
                    params = {
                        msgType: 'custom',
                        msgOptions: params.msgOptions
                    }
                }
                if (params.msgOptions.chatType == 'groupChat') {
                    // 群聊
                    msgData.toGroup = params.msgOptions.id
                    // state
                    //console.log("%c Line:211 🥝 Groups", "color:#e41a6a", Groups.state.groupsInfos);
                    // if(Groups.state.groupsInfos[params.msgOptions.id]){
                    //   let persons = Groups.state.groupsInfos[params.msgOptions.id]
                    // }
                } else if (params.msgOptions.chatType == 'singleChat') {
                    // 单聊
                    msgData.toUser = params.msgOptions.id
                }

                const errorCallback = (error) => {
                    reject(error)
                }

                const options = createMessage.createOptions(
                    params,
                    errorCallback
                )
                const msg = EaseChatSDK.message.create(options)
                if (state.messageExt) {
                    msgData.ext = state.messageExt
                }
                sendMsg(msgData)
                    .then((res) => {
                        commit('UPDATE_messageExt', '')
                        //console.log('%c Line:138 🍋 res', 'color:#93c0a4', res)
                        if (res.code == 200) {
                            // if (params.msgType == 'b_backlist'||params.msgType == 'b_backorder'){
                            // }else{
                            const msgIdMap = res.data.msgIdMap
                            msg.id = msgIdMap[params.msgOptions.id]
                            msg.from = EaseChatClient.user
                            const msgBody = createMessage.createMsgBody(msg)
                            msgBody.type =
                                msgData.senType == 'text' ? 'txt' : 'custom'
                            msgBody.customEvent =
                                msgData.senType == 'text' ? '' : msgData.senType
                            if (msgData.senType != 'text') {
                                msgBody.customExts = {
                                    content: msgData.content
                                }
                            }
                            if (msgData.toUser) {
                                commit('UPDATE_MESSAGE_LIST', msgBody)
                            }
                            // 提示会话列表更新
                            dispatch('gatherConversation', msgBody.to)
                            // 将消息存到本地未读消息列表 只存单聊的
                            if (params.msgOptions.chatType == 'singleChat') dispatch('addMsgToUnreadIds', msg.id)
                            // }
                            commit('SET_INPUT_QUICK_WORD', '')


                            resolve('OK')
                        }
                    })
                    .catch((err) => {
                        let obj = {
                            type: 'error',
                            message: err.msg
                        }
                        reject(obj)
                    })
                // EaseChatClient.send(msg)
                //     .then((res) => {
                //         const { serverMsgId } = res
                //         //console.log('>>>>发送成功demo', res)
                //         msg.id = serverMsgId
                //         msg.from = EaseChatClient.user
                //         const msgBody = createMessage.createMsgBody(msg)
                //         commit('UPDATE_MESSAGE_LIST', msgBody)
                //         // 提示会话列表更新
                //         dispatch('gatherConversation', msgBody.to)
                //         resolve('OK')
                //     })
                //     .catch((error) => {
                //         reject(error)
                //     })
            })
        },
        //添加通知类消息
        createInformMessage: ({ dispatch, commit }, params) => {
            /** 
               const params = {
                    from: '',
                    to: '',
                    chatType: '',
                    msg:''
                }
            */
            const msgBody = _.cloneDeep(params)
            msgBody.type = ALL_MESSAGE_TYPE.INFORM
            const key = setMessageKey(params)
            //console.log('>>>>>>添加系统消息', params)
            commit('UPDATE_MESSAGE_LIST', msgBody)
            dispatch('gatherConversation', key)
        },
        //撤回消息
        recallMessage: async ({ dispatch, commit }, params) => {
            const { mid, to, chatType } = params
            return new Promise((resolve, reject) => {
                EaseChatClient.recallMessage({ mid, to, chatType })
                    .then(() => {
                        let sessionId = ''
                        if (chatType == 'groupChat') {
                            sessionId = to
                        } else {
                            const loginUserId = EaseChatClient.user
                            if (loginUserId > to) {
                                sessionId = loginUserId + to
                            } else {
                                sessionId = to + loginUserId
                            }
                        }
                        revocationMsg({ messageId: mid, sessionId })
                        commit('CHANGE_MESSAGE_BODAY', {
                            type: 'recall',
                            key: to,
                            mid
                        })
                        dispatch('gatherConversation', to)
                        resolve('OK')
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }
    }
}
export default Message
